import React, { useContext } from 'react';
import Sticky from 'react-sticky-el';
import { Header, Footer } from 'components';
import ModalSearch from 'components/ModalSearch/ModalSearch';
import { SearchProvider } from 'constants/searchContext';
import { LocaleProvider, LocaleContext } from 'constants/localeContext';
import Headroom from 'react-headroom';
import { isMobile } from 'react-device-detect';
import { Menu } from 'components';


const MainLayout = ({ children, ...rest }) => (
  <div>
    <LocaleProvider>
      <AppContainer {...rest}>
        {children}
      </AppContainer>

      <Footer {...rest} />
    </LocaleProvider>
  </div>
  );

  const AppContainer = ({ children, ...rest }) => {
    const [locale] = useContext(LocaleContext);
    return (
      <div className="App" id="App" data-locale={locale}>
        <App {...rest}>
          {children}
        </App>
      </div>
    );
  };


const App = ({ children, ...rest }) => (
  <>
    <SearchProvider>
      <ModalSearch />
      <div className="header-fixed-container" id="header-fixed-container">
        <main className="main">
          <div className="content-container">
            {children}
          </div>
        </main>
        <Sticky className="sticky-header" boundaryElement=".header-fixed-container" positionRecheckInterval="0" dontUpdateHolderHeightWhenSticky="true">
          { isMobile ? <Headroom><Header {...rest} /></Headroom> : <Header {...rest} /> }
        </Sticky>
      </div>
    </SearchProvider>
  </>
  );

const FixedMenu = () => {

}

export default MainLayout;
