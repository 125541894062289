import React, { useEffect } from 'react';
import { Error, Loading, AbsoluteCenter } from 'components';
import { useGlobalContext } from 'constants/context';
import useBreakpoint from 'hooks/useBreakpoint';
import { useInfiniteCatalogScroll } from './hooks/useInfiniteCatalogScroll';
import { useIsInitialLoading } from './hooks/useIsInitialLoading';
import ItemSearch from './ItemSearch';

export default function ItemSearchContainer() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const { contextState: { searchValue } } = useGlobalContext();
  const { breakpoint } = useBreakpoint();
  const { LoadMoreButton, infiniteRef, error, items, refetch, loading, count } = useInfiniteCatalogScroll({ breakpoint, searchValue });
  const isInitialLoading = useIsInitialLoading(loading);
  if (isInitialLoading) return (<AbsoluteCenter><Loading center /></AbsoluteCenter>);
  if (error) return <Error error={error} retry={refetch} />;

  return (
    <ItemSearch
      ref={infiniteRef}
      loading={loading}
      items={items}
      searchValue={searchValue}
      LoadMoreButton={LoadMoreButton}
      count={count}
    />
  );
}
