import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RenderHtml } from "components";

const LeftArrowIcon = (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>DBD5E95B-4710-4489-BF02-C6A1C9C9F534</title>
    <g
      id="LibraryCat-Mockups-(SKA)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SKA-1.4-Item"
        transform="translate(-336.000000, -455.000000)"
        fill="#000000"
      >
        <g id="Arrow-left-32" transform="translate(336.000000, 455.000000)">
          <polygon
            id="Fill-1"
            points="24.0944263 0 8 16 24.0944263 32 25 31.0417423 9.86969749 16 25 0.959259027"
          />
        </g>
      </g>
    </g>
  </svg>
);

const RightArrowIcon = (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>5271F461-A0AF-45E1-99A4-91397F842351</title>
    <g
      id="LibraryCat-Mockups-(SKA)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SKA-1.4-Item"
        transform="translate(-1320.000000, -455.000000)"
        fill="#000000"
      >
        <g id="Arrow-right-32" transform="translate(1320.000000, 455.000000)">
          <polygon
            id="Fill-1"
            transform="translate(16.500000, 16.000000) rotate(-180.000000) translate(-16.500000, -16.000000) "
            points="24.0944263 -1.58806301e-12 8 16 24.0944263 32 25 31.0417423 9.86969749 16 25 0.959259027"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default function SimpleSlider({ images = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: LeftArrowIcon,
    nextArrow: RightArrowIcon,
    draggable: true,
    afterChange(index) {
      setCurrentIndex(index);
    },
  };
  if (!images) return null;
  if (Array.isArray(images) && images.length === 0) return null;
  return (
    <div className="slider-container">
      <div>
        <Slider {...settings}>
          {images.map((image) => (
            <div key={image.subtitle}>
              <div className="item-image">
                <img src={image.url} alt={image.subtitle} />
              </div>
            </div>
          ))}
        </Slider>
        <div className="image-subtitle">
          <RenderHtml html={images[currentIndex]?.subtitle} />
        </div>
      </div>
    </div>
  );
}
