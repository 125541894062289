import React, { useState, useEffect, useCallback } from 'react';
import { Error, Loading, AbsoluteCenter } from 'components';
import { useGlobalContext } from 'constants/context';
import useBreakpoint from 'hooks/useBreakpoint';
import { useIsInitialLoading } from './hooks/useIsInitialLoading';
import Books from './Books';
import { useInfiniteCatalogScroll } from './hooks/useInfiniteCatalogScroll';
import { useFiltersFetch } from './hooks/useFiltersFetch';
import { sortOptions } from './SortSelect';

export default function HomeContainer() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const [sort, setSort] = useState(sortOptions[0]);

  const { contextState: { filters: selectedFilters = [] }, setContextState } = useGlobalContext();
  const setSelectedFilters = useCallback((newValue) => setContextState((contextState) => ({ ...contextState, filters: (typeof newValue === 'function') ? newValue(contextState.filters || []) : newValue, searchValue: '' })), [setContextState]);
  const { /* loading: loadingFilters, */ filters } = useFiltersFetch();
  const { breakpoint } = useBreakpoint();
  const { LoadMoreButton, infiniteRef, error, items, refetch, loading, count } = useInfiniteCatalogScroll({ breakpoint, sort: sort.value, filters: selectedFilters });
  const isInitialLoading = useIsInitialLoading(loading);
  if (isInitialLoading) return (<AbsoluteCenter><Loading center /></AbsoluteCenter>);
  if (error) return <Error error={error} retry={refetch} />;
  return (
    <Books
      ref={infiniteRef}
      loading={loading}
      items={items}
      sort={sort}
      setSort={setSort}
      filters={filters}
      LoadMoreButton={LoadMoreButton}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      count={count}
      breakpoint={breakpoint}
    />
  );
}
