import usePagesInfo from 'hooks/usePagesinfo';
import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';


export default function Infos() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
    return (
      <div>
        <div className="page-name"><h1 className="i18n-tr">Info</h1><h1 className="i18n-en">Info</h1></div>
        <div className="infos-container translate-container">
          {/* <div className="row">
            <div className="menu-links i18n-tr">
              <h2><HashLink to="#about">Hakkında</HashLink></h2>
              <h2><HashLink to="#activities">Aktiviteler</HashLink></h2>
              <h2><HashLink to="#support">Destek</HashLink></h2>
            </div>
            <div className="menu-links i18n-en">
              <h2><HashLink to="#about">About</HashLink></h2>
              <h2><HashLink to="#activities">Activities</HashLink></h2>
              <h2><HashLink to="#support">Support</HashLink></h2>
            </div>
          </div> */}
          { usePagesInfo('/ska/menu-info/')}
          {/* to backend */}
          <div className="infos-content">
            { usePagesInfo('/ska/info/')}
          </div>
          {/* end to backend */}
        </div>
      </div>
    );
}
