import React, { useEffect } from 'react';
import useApiCall from 'hooks/useApiCall';
import ArtphileinLibrary from 'services/artphileinlibrary';
import get from 'lodash/get';
import { ArtistPreview } from '.';

const ArtistPreviewContainer = (props) => {
  const slug = get(props, 'match.params.slug', '');
  // useEffect(() => { window.scroll({ top: 0, left: 0, behavior: 'smooth' }); }, [slug])
  useEffect(() => { window.scrollTo(0, 0); }, [slug]);
  // get data for story
  const { data, error, component } = useApiCall(() => ArtphileinLibrary.artist({ slug }), [slug]);
  const story = React.useMemo(() => {
    if (!data) return null;
    return {
      ...data,
    };
  }, [data]);
  if (error && error.message && typeof error.message === 'string' && typeof error.message.includes === 'function' && error.message.includes('404')) {
    // return <NotFoundStoryMessage />;
    return 'Not found';
  }
  if (component) {
    return component;
  }

  return (
    <ArtistPreview story={story} />
  );
};

  export default ArtistPreviewContainer;
