import React, { useContext } from 'react';
import get from 'lodash/get';
import { Error, RenderHtml } from 'components';
import { StaticPagesContext } from 'components/StaticPagesProvider';

const usePagesInfo = (route, replace) => {
  const { data, error, refetch } = useContext(StaticPagesContext);
  const pages = get(data, 'pages');
  if (error) return <Error error={error} retry={refetch} />;
  return (
    <>
      {pages && pages
        .filter((page) => (
          page.page_name === route))
        .map((page) => (
          <RenderHtml key={page.page_name} html={page.content} replace={replace} />
        ))}
    </>
  );
};

export default usePagesInfo;
