import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import StaticPagesProvider from 'components/StaticPagesProvider';
import MainLayout from './layouts/MainLayout';
import { Home, NotFound, ItemPreview, Books, Infos, Artists, ArtistPreviewContainer, ItemSearch } from './pages';
import ThemeProvider from './theme';
import { GlobalContextProvider } from './constants/context';

const App = () => (
  <StaticPagesProvider>
    <ThemeProvider>
      <Router>
        <GlobalContextProvider>
          <MainLayout>
            <Helmet>
              <meta name="title" content="SKA – Sanatçı Kitabı Alanı / Space for Artists’ Books" />
              <meta name="description" content="Independent, non-profit space based in Beyoğlu İstanbul that focuses on artists’ books and printed matter from Turkey." />
            </Helmet>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/items" exact component={Books} />
              <Route path="/items/:slug" exact component={ItemPreview} />
              <Route path="/search" exact component={ItemSearch} />
              <Route path="/artists" exact component={Artists} />
              <Route path="/artists/:slug" exact component={ArtistPreviewContainer} />
              <Route path="/about" exact component={Infos} />
              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </GlobalContextProvider>
      </Router>
    </ThemeProvider>
  </StaticPagesProvider>
);

export default App;
