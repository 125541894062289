import React, { useEffect } from 'react';
import { Link } from 'components';
import ArtphileinLibrary from 'services/artphileinlibrary';
import useApiCall from 'hooks/useApiCall';
import get from 'lodash/get';

const Artists = () => {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { data } = useApiCall(() => ArtphileinLibrary.artists({ page: 1 }));
  const artists = get(data, 'results');
  let currentLetter = '';
  const newLetter = (letter) => {
    currentLetter = letter;
  };
  return (
    <div>
      <div className="page-name">
        <div className="i18n-tr">
          Sanatçılar
        </div>
        <div className="i18n-en">
          Artists
        </div>
      </div>
      <div className="artists-container">
        { artists && artists
      .map((artist) => (
        <div className="name-block" key={artist.name}>
          {currentLetter !== artist.name[0]
          ? (newLetter(artist.name[0]), <div className="current-letter">{currentLetter}</div>)
          : <div className="current-letter" /> }
          <Link to={artist.slug} className="artist-link">
            {artist.name}
          </Link>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Artists;
