import React from 'react';
import usePagesInfo from 'hooks/usePagesinfo';
import styled from 'styled-components';
import { Menu } from 'components';


const StyledInfo = styled.div`
  display:flex;
  @media(max-width: 767px) {
    display:block !important;
  }
  font-size:24px;
  line-height:1.6;
  flex-wrap:wrap;
`;


const StyledInfoTURK = styled.div`
  @media(max-width: 767px) {
    width:100%;
    margin: inherit;
  }
`;

const HomeInfo = () => (
  <StyledInfo>
    <StyledInfoTURK>
      <Menu />
      <div className="translate-container">
        { usePagesInfo('/ska/home/')}
      </div>
    </StyledInfoTURK>
  </StyledInfo>
);


export default HomeInfo;
