import React from 'react';
import { Item } from 'components';
import classnames from 'classnames';

const Catalog = (props) => {
  const {
    items,
    className = '',
    xl,
    ...rest
  } = props;
  return (
    <div className={classnames('Catalog', { xl }, className)}>
      {items && items.map((item) => (
        <div className="item-container" key={`${item.slug}-${item.id}`}>
          <Item
            {...item}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
};

export default Catalog;
