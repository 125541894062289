import React from 'react';
import IconImage from './Icon.svg';
import Link from './Link';

export const Icon = ({ iconAtStart, iconAtEnd = !iconAtStart, iconSize = 20, children, marginTopIcon = 'auto', marginIconText = 10, className = '', ...props }) => (
  <Link className={`link-with-icon ${className}`} {...props}>
    <div style={{ display: 'flex' }}>
      {iconAtStart && <><img className="icon-at-start" src={IconImage} style={{ width: iconSize, height: iconSize, marginTop: marginTopIcon, marginBottom: 'auto', marginRight: marginIconText }} alt="" />{' '}</>}
      <span>{children}</span>
      {iconAtEnd && <><img className="icon-at-end" src={IconImage} style={{ width: iconSize, height: iconSize, marginTop: marginTopIcon, marginBottom: 'auto', marginLeft: marginIconText }} alt="" /></>}
    </div>
  </Link>
);
export default Icon;
