import React from 'react';
import { Catalog } from 'components';
import { Helmet } from 'react-helmet';


const ArtistPreview = ({ story }) => {
  const isArrayNotEmpty = (arr) => !!(arr && arr.length);
  const {
    items,
    name,
    website,
    working_place: workingPlace,
  } = story;
  const isNameEmpty = isArrayNotEmpty(name);
  const isWebsiteEmpty = isArrayNotEmpty(website);
  const isPlaceEmpty = isArrayNotEmpty(workingPlace);
  return (
    <div>
      <Helmet>
        <meta name="title" content={name} />
        <meta name="description" content="SKA – Sanatçı Kitabı Alanı / Space for Artists’ Books. Beyoğlu İstanbul" />
      </Helmet>
      { isNameEmpty && (<div className="page-name">{name}</div>) }
      { (isWebsiteEmpty || isPlaceEmpty) && (
      <div className="website-work">
        { (<div>{website}</div>) }
        { (<div>{workingPlace}</div>) }
      </div>
) }
      {items && <div className="related-items"><Catalog items={items} /> </div> }
    </div>
  );
};
export default ArtistPreview;
