import React from 'react';
import { Link } from 'components';
import parse from 'html-react-parser';

function parseWithLinks(text, replace) {
  const options = {
    replace: ({ name, attribs, children, ...rest }) => {
      if (name === 'a' && attribs.href[0] === ('/')) {
        return <Link to={attribs.href}>{Object.values(children).map((text) => (text.data))}</Link>;
      }
      if (typeof replace === 'function') return replace({ name, attribs, children, ...rest }) || 0;
      return 0;
    },
  };
  return parse(text, options);
}

const RenderHtml = ({ html, replace }) => {
  if (!html || typeof html !== 'string') return null;
  return parseWithLinks(html, replace);
};

export default RenderHtml;
