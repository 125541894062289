import React, { useContext } from 'react';
// import { Link } from 'components';
// import { HashLink } from 'react-router-hash-link';
import { LocaleContext } from 'constants/localeContext';
import Button from 'components/Button';
import usePagesInfo from 'hooks/usePagesinfo';

const Menu = () => {
  const [locale, setLocale] = useContext(LocaleContext);
  return (
    <div className="menu-block">
      { usePagesInfo('/ska/menu-main/')}
      {/* <div className="row">
        <div className="i18n-tr">
          <h1 className="menu-name">
            <Link to="/">Sanatçı Kitabı Alanı</Link>
          </h1>
          <div className="menu-links">
            <h2>
              <Link to="/items/">Yayınlar</Link>
            </h2>
            <h2>
              <Link to="/artists/">Sanatçılar</Link>
            </h2>
            <h2>
              <Link to="/about/">Bilgi</Link>
            </h2>
            <h2>
              <HashLink to="/about/#support">Destek</HashLink>
            </h2>
          </div>
        </div>
        <div className="i18n-en">
          <h1 className="menu-name">
            <Link to="/">Space for Artists’ Books</Link>
          </h1>
          <div className="menu-links">
            <h2>
              <Link to="/items/">Books</Link>
            </h2>
            <h2>
              <Link to="/artists/">Artists</Link>
            </h2>
            <h2>
              <Link to="/about/">Info</Link>
            </h2>
            <h2>
              <HashLink to="/about/#support">Support</HashLink>
            </h2>
          </div>
        </div>
      </div> */}
      <div className="locale-selector">
        <Button
          className={`${locale === 'tr' ? 'active' : ''}`}
          onClick={() => setLocale('tr')}
        >
          Türkçe
        </Button>
        <Button
          className={`${locale === 'en' ? 'active' : ''}`}
          onClick={() => setLocale('en')}
        >
          English
        </Button>
      </div>
    </div>
  );
};

export default Menu;
