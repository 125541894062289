import React from 'react';
import ArtphileinLibrary from 'services/artphileinlibrary';
import useApiCall from 'hooks/useApiCall';
// import cache from 'plain-cache';

// const cacheKey = 'static-pages';
export const StaticPagesContext = React.createContext({
  error: null,
  refetch: () => {},
  data: null,
});

const StaticPagesProvider = ({ children }) => {
  // const { error, data, refetch } = useApiCall(async () => {
  //   const cached = cache.get(cacheKey);
  //   if (cached) return cached;

  //   const data = await ArtphileinLibrary.pages();
  //   cache.set(cacheKey, data, cache.VALID_FOR_30_SECONDS);
  //   return data;
  // });
  const { error, data, refetch } = useApiCall(ArtphileinLibrary.pages);

  return <StaticPagesContext.Provider value={{ error, data, refetch }}>{children}</StaticPagesContext.Provider>;
};

export default StaticPagesProvider;
