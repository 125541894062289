import React from 'react';
import styled from 'styled-components';
import usePagesInfo from 'hooks/usePagesinfo';
import { useRouteMatch } from 'react-router-dom';
import FooterMenu from './FooterMenu';
import { ReactComponent as EnvelopeIcon } from '../../assets/envelope.svg';

// const LogoText = styled.span`
//   font-size:24px;
//   font-weight:bold;
//   white-space:nowrap
// `;
const Container = styled.footer`
  padding: 55px 0;
  ${
    '' /* ${media.greaterThan('1624px')`
  padding: 90px 0px 100px;
  `} */
  }
  min-height: 232px;
  flex-wrap: wrap;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
`;
const Footer = () => {
  const match = useRouteMatch('/');
  return (
    <div>
      {match.isExact ? null : <FooterMenu />}
      <Container>
        <Column className="footer-container">
          <Row className="catalog-website-line content-container">
            {usePagesInfo('/ska/footer/', ({ name, attribs }) => {
              if (name === 'label') {
                return <></>;
              }
              if (name === 'input' && attribs.type === 'email') {
                const { value, ...attribsWithoutValue } = attribs;
                return (
                  <>
                    <input placeholder="Email" {...attribsWithoutValue} />
                    <button type="submit" className="input-submit-btn-icon">
                      <EnvelopeIcon />
                    </button>
                  </>
                );
              }
              return null;
            })}

            <table className="ska-year-madeby">
              <tbody>
                <tr>
                  <td>© {new Date().getFullYear()} SKA</td>
                  <td>
                    Website by <a href="https://edcat.net">edcat.net</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
        </Column>
      </Container>
    </div>
  );
};

export default Footer;
