import React from 'react';
import styled from 'styled-components';
import { Menu } from 'components';

const Container = styled.div`
    padding: 20px 0 20px;
  ${'' /* ${media.greaterThan('1624px')`
  padding: 90px 0px 100px;
  `} */}
  min-height: 232px; 
  flex-wrap: wrap;
  display:flex;
  justify-content: center;
  align-items: center;
`;
const Column = styled.div`
  display: flex;
  max-width: 1400px;
  width:100%;
  padding-left:200px;
  flex-direction:column;
`;

const FooterMenu = () => (
  <Container className="footer-menu">
    <Column className="footer-container">
      <div className="content-container">

        <Menu />
      </div>
    </Column>
  </Container>
  );

export default FooterMenu;
