import React, { useState, useEffect } from 'react';
import { Link, Button } from 'components';
import classnames from 'classnames';
import useBreakpoint from 'hooks/useBreakpoint';
import styled from 'styled-components';
import { SearchContext } from 'constants/searchContext';
import { Menu } from 'components';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';

const LogoIconAlternative = (
  <svg
    width="107px"
    height="143px"
    viewBox="0 0 107 143"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="LibraryCat-Mockups-(SKA)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SKA-1.4-LOGO-HOVER"
        transform="translate(-119.000000, -62.000000)"
        fill="#6F2EB9"
      >
        <g id="Graphic/Logo-1" transform="translate(119.000000, 62.000000)">
          <path
            d="M1.63882723,24.5630323 L82.6866341,102.829357 L82.6866341,142.61787 L2.27853711,142.61787 C2.27853711,142.61787 -0.000161000808,142.61787 -0.000161000808,140.338904 L0.000144816238,25.2374639 C0.00519079749,25.0108538 0.0934954694,23.0706554 1.63882723,24.5630323 Z M87.5520071,107.756032 L105.33251,124.585544 C105.33251,124.585544 106.578031,125.708156 105.33251,126.830231 L87.5520071,142.054479 C87.5520071,142.054479 86.3064854,143.17709 86.3064854,141.561625 L86.3064854,108.249422 C86.3064854,108.249422 86.3064854,106.633957 87.5520071,107.756032 Z M106.438902,2.3854 L106.438902,117.46513 C106.438902,117.46513 106.438902,119.744096 104.810763,118.161272 L24.3263207,39.8949468 L24.3263207,0.106434043 L104.203069,0.107046031 C104.458593,0.115919854 106.438902,0.253464105 106.438902,2.3854 Z M20,1.87041438 L20,35.1121883 C20,35.1121883 20,36.7243451 18.7913479,35.6037517 L1.53717873,18.8119676 C1.53717873,18.8119676 0.328526586,17.6919091 1.53717873,16.5718506 L18.7913479,1.37885099 C18.7913479,1.37885099 20,0.258792504 20,1.87041438 Z"
            id="Fill-2"
          />
        </g>
      </g>
    </g>
  </svg>
);

const LogoIcon = (
  <svg
    width="107px"
    height="143px"
    viewBox="0 0 107 143"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="LibraryCat-Mockups-(SKA)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SKA-1.4"
        transform="translate(-119.000000, -62.000000)"
        fill="#000000"
      >
        <g id="Graphic/Logo-1" transform="translate(119.000000, 62.000000)">
          <path
            d="M1.63882723,24.5630323 L82.6866341,102.829357 L82.6866341,142.61787 L2.27853711,142.61787 C2.27853711,142.61787 -0.000161000808,142.61787 -0.000161000808,140.338904 L0.000144816238,25.2374639 C0.00519079749,25.0108538 0.0934954694,23.0706554 1.63882723,24.5630323 Z M87.5520071,107.756032 L105.33251,124.585544 C105.33251,124.585544 106.578031,125.708156 105.33251,126.830231 L87.5520071,142.054479 C87.5520071,142.054479 86.3064854,143.17709 86.3064854,141.561625 L86.3064854,108.249422 C86.3064854,108.249422 86.3064854,106.633957 87.5520071,107.756032 Z M106.438902,2.3854 L106.438902,117.46513 C106.438902,117.46513 106.438902,119.744096 104.810763,118.161272 L24.3263207,39.8949468 L24.3263207,0.106434043 L104.203069,0.107046031 C104.458593,0.115919854 106.438902,0.253464105 106.438902,2.3854 Z M20,1.87041438 L20,35.1121883 C20,35.1121883 20,36.7243451 18.7913479,35.6037517 L1.53717873,18.8119676 C1.53717873,18.8119676 0.328526586,17.6919091 1.53717873,16.5718506 L18.7913479,1.37885099 C18.7913479,1.37885099 20,0.258792504 20,1.87041438 Z"
            id="Fill-2"
          />
        </g>
      </g>
    </g>
  </svg>
);

const SearchIcon = (
  <svg
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="LibraryCat-Mockups-(SKA)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SKA-1.4-Search-Results"
        transform="translate(-143.000000, -784.000000)"
      >
        <rect fill="#fff" x="0" y="0" width="1464" height="1443" />
        <g
          id="Organism/Footer-SKA-1.3"
          transform="translate(0.000000, 741.000000)"
        />
        <g
          id="Atom/Icon/Search-Small"
          transform="translate(143.000000, 784.000000)"
          fill="#000000"
        >
          <path
            d="M36.3960568,37.9269353 C33.6126413,40.385619 29.947811,41.8701073 25.9582488,41.8701073 C17.1440997,41.8701073 10,34.7260075 10,25.9118585 C10,17.0977095 17.1440997,10 25.9582488,10 C34.7260075,10 41.8701073,17.0977095 41.8701073,25.9118585 C41.8701073,29.9014207 40.385619,33.5662511 37.9733256,36.3960568 L49.7564511,48.1791824 C50.081183,48.5039142 50.081183,48.7358655 49.7564511,49.0605973 L49.0605973,49.7564511 C48.7358655,50.081183 48.5503044,50.081183 48.2255726,49.7564511 L36.3960568,37.9269353 Z M25.9582488,12.1803421 C18.3502464,12.1803421 12.2267324,18.3502464 12.2267324,25.9118585 C12.2267324,33.4734706 18.3502464,39.6433749 25.9582488,39.6433749 C33.5198608,39.6433749 39.6433749,33.4734706 39.6433749,25.9118585 C39.6433749,18.3502464 33.5198608,12.1803421 25.9582488,12.1803421 Z"
            id="search-icon"
          />
        </g>
      </g>
    </g>
  </svg>
);

// const baseUrl = process.env.REACT_APP_EDCAT_URL;

const FullWidthDiv = styled.div`
  width: 100%;
`;
const HeaderComponent = styled.header`
  .mobile-nav .form-search {
    input {
      background: #f2f5f4;
      &::placeholder {
        font-size: 26px;
      }
    }
    svg:not(.search-icon) {
      display: none;
    }
    svg {
      margin-left: -30px;
      pointer-events: none;
    }

    ${'' /* background: blue; */}
  }
`;

function useSearch() {
  const context = React.useContext(SearchContext);
  const [toogleSearch, setToogleSearch] = context;
  return {
    toogleSearch,
    setToogleSearch,
  };
}

const Header = () => {
  const { toogleSearch, setToogleSearch } = useSearch();
  const [isShownMenu, setIsShownMenu] = useState(false);
  const handleClickSearch = (e) => {
    e.preventDefault();
    setToogleSearch(!toogleSearch);
  };

  useEffect(() => {
    if (isShownMenu && window.innerWidth < 767) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }
  }, [isShownMenu]);

  // const menuTurk = usePagesInfo('/ska/menu-tr/');
  // const menuEng = usePagesInfo('/ska/menu-en/');

  // console.log(isDisplaySearchBar);
  const history = useHistory();
  const location = useLocation();

  useEffect(
    () => history.listen(() => {
        setIsShownMenu(false);
      }),
    [history],
  );
  return (
    <div>
      <HeaderComponent className={classnames('Header')}>
        <div className="header-container">
          <div
            className="logo-container"
            onMouseEnter={() => setIsShownMenu(true)}
            onMouseLeave={() => setIsShownMenu(false)}
          >
            {isMobile ? (
              <div
                className="logo-link"
                onClick={() => {
                  if (location.pathname === '/') {
                    setIsShownMenu(false);
                  } else {
                    setIsShownMenu((s) => !s);
                  }
                }}
              >
                {!isShownMenu ? LogoIcon : LogoIconAlternative}
              </div>
            ) : (
              <Link className="logo-link" to="/">
                {!isShownMenu ? LogoIcon : LogoIconAlternative}
              </Link>
            )}
            {isShownMenu && location.pathname !== '/' ? (
              <div className="menu-container">
                <Menu />
              </div>
            ) : null}
          </div>

          <FullWidthDiv className="search-icon-div">
            {/* <TransitionDiv visible={isDisplaySearchBar}>
              {SearchComponent}
              <LinkWithIcon marginTopIcon={-2} className="logo-link hide-on-mobile" to="/" local={false}>
                <span style={{ fontSize: 15, whiteSpace: 'nowrap' }}>Artphilein Library Website</span>
              </LinkWithIcon>
            </TransitionDiv> */}
            <Button className="search-icon" onClick={handleClickSearch}>
              {SearchIcon}
            </Button>
          </FullWidthDiv>
        </div>
      </HeaderComponent>
    </div>
  );
};
export const WithBreakpoint = (Component) => function WithBreakpoint(props) {
    return <Component {...props} breakpoint={useBreakpoint().breakpoint} />;
  };
const MemoHeader = React.memo(Header);
export default WithBreakpoint(MemoHeader);
