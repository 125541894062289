import React from 'react';
import { Catalog } from 'components';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { Button, Link } from 'components';

const StyledHome = styled.div`
  padding: 0;
`;


const ItemSearch = ({ items, loading, searchValue, LoadMoreButton, count }, infiniteCatalogRef) => (
  <div>
    <div className="page-name"><div className="i18n-tr">Yayınlar</div><div className="i18n-en">Books</div></div>
    <StyledHome className="Home">
      <div className="row">
        <div className="item-filter-container">
          <div className="item-filter-wrapper">
            <div className="items-count">&quot;{searchValue}&quot; için { searchValue ? count : 0} Sonuç</div>
            <div className="items-count">{ searchValue ? count : 0} {items.length > 1 ? 'results' : 'result' } for &quot;{searchValue}&quot;</div>
          </div>
        </div>
      </div>
      {(items.length && searchValue)
        ? (<div ref={infiniteCatalogRef}><Catalog items={items} /></div>)
        : !loading && <div className="no-results">No results{searchValue && <> for <span>&bdquo;</span>{searchValue}<span>&ldquo;</span></>}</div>}
      {LoadMoreButton}
      <Link to="/items">
        <Button className="all-related-books-btn">
          Hepsini Göster | show all
        </Button>
      </Link>
      {loading && <div className="loading-text">Loading more...</div>}
    </StyledHome>
  </div>
  );
const HomeWithRef = React.forwardRef(ItemSearch);
const HomeMemo = React.memo(HomeWithRef, isEqual);
export default HomeMemo;
