import React, { useState, useMemo, useCallback, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'constants/context';
import useSearch from 'hooks/useSearch';
import usePrevious from 'hooks/usePrevious';
import { SearchContext } from 'constants/searchContext';
import { isMobile } from 'react-device-detect';
import { Nav } from './components';

const TransitionDiv = styled.div`
display: flex;
width:100%;
opacity:1;
transition: all 0.4s;
${(props) => !props.visible && `
  visibility: hidden;
  opacity:0;
`}
.nav-container{
  display:flex;
  >.nav{
    margin:auto;
  }
  +* .search-icon{
    display:none;
  }
}
`;

const FullWidthDiv = styled.div`
  width: 100%;
`;

const FormSearchContainer = styled.form`
  width: 100%;
  display: flex;
  svg {
    cursor:pointer;
    margin:auto;
    margin-left:30px;
    height:30px;
  }
`;

const StyledInput = styled.input`
  padding: 13px;
  background-color: #6F2EB9;
  width: 100%;
  font-size: 40px;
  line-height: normal;
  border: none;
  color: #fff;
  outline: none;
  ${'' /* margin-top: -2px; */}
  caret-color: #fff;
  &::placeholder,
  &::-webkit-input-placeholder {
    color:#fff !important;
    opacity: 50% !important;
  };
  :-ms-input-placeholder {
    color:#fff !important;
    opacity: 50% !important;
  }
`;

const SearchIcon = (
  <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>C3B29743-6609-4CB7-B042-BED81D8E805E</title>
    <g id="LibraryCat-Mockups-(SKA)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SKA-1.4-Search" transform="translate(-131.000000, -169.000000)">
        <rect fill="#6F2EB9" x="0" y="0" width="1464" height="1103" />
        <g id="Atom/Icon/Search-Small" transform="translate(131.000000, 169.000000)" fill="#FFFFFF">
          <path d="M36.3960568,37.9269353 C33.6126413,40.385619 29.947811,41.8701073 25.9582488,41.8701073 C17.1440997,41.8701073 10,34.7260075 10,25.9118585 C10,17.0977095 17.1440997,10 25.9582488,10 C34.7260075,10 41.8701073,17.0977095 41.8701073,25.9118585 C41.8701073,29.9014207 40.385619,33.5662511 37.9733256,36.3960568 L49.7564511,48.1791824 C50.081183,48.5039142 50.081183,48.7358655 49.7564511,49.0605973 L49.0605973,49.7564511 C48.7358655,50.081183 48.5503044,50.081183 48.2255726,49.7564511 L36.3960568,37.9269353 Z M25.9582488,12.1803421 C18.3502464,12.1803421 12.2267324,18.3502464 12.2267324,25.9118585 C12.2267324,33.4734706 18.3502464,39.6433749 25.9582488,39.6433749 C33.5198608,39.6433749 39.6433749,33.4734706 39.6433749,25.9118585 C39.6433749,18.3502464 33.5198608,12.1803421 25.9582488,12.1803421 Z" id="search-icon" />
        </g>
      </g>
    </g>
  </svg>
);

const ExitIcon = (
  <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>9735A875-B224-48B7-B247-714DE683FF73</title>
    <g id="LibraryCat-Mockups-(SKA)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SKA-1.4-Search" transform="translate(-131.000000, -52.000000)" fill="#FFFFFF">
        <g id="Atom/Icon/Search-Small-Copy" transform="translate(131.000000, 52.000000)">
          <polygon id="Fill-1" points="40.4691448 17.5 30.000957 27.9701018 19.5308552 17.5 17.5 19.5308552 27.9701018 29.999043 17.5 40.4710589 19.5308552 42.5 30.000957 32.0318123 40.4691448 42.5 42.5 40.4710589 32.0298982 29.999043 42.5 19.5308552" />
        </g>
      </g>
    </g>
  </svg>
);

const denyClick = (e) => { e.preventDefault(); e.stopPropagation(); };

const ModalSearch = ({ breakpoint }) => {
  const searchInputRef = useRef();
  const [searchText, setSearchText] = useState('');
  const [toogleSearch, setToogleSearch] = useContext(SearchContext);
  const { contextState: { searchValue } } = useGlobalContext();
  const { searchValue: prevSearchValue } = usePrevious({ searchValue });
  if (prevSearchValue !== searchValue && searchValue !== searchText) setSearchText(searchValue); // update state if context changed
  const onSearchValueChanged = useCallback((e) => setSearchText(e.target.value), []);
  const { setSearch, ifNotHomeGoToHome } = useSearch();
  const onSubmitSearch = useCallback((e) => { e.preventDefault(); e.stopPropagation(); setSearch(searchText); ifNotHomeGoToHome(searchText); setToogleSearch(false); }, [ifNotHomeGoToHome, searchText, setSearch, setToogleSearch]);

  const handleExitModal = useCallback(() => {
    setToogleSearch(!toogleSearch);
}, [toogleSearch, setToogleSearch]);

  const headerItems = useCallback(({ onClickSearchIcon }) => [
    {
      label: ExitIcon,
      onClick: handleExitModal,
    },
    {
      label: SearchIcon,
      onClick: onClickSearchIcon,
    },
  ], [handleExitModal]);

  // console.log(isDisplaySearchBar);
  const SearchComponent = (className) => (
    <FormSearchContainer className={`form-search ${className || ''}`} onClick={denyClick} onSubmit={onSubmitSearch}>
      {isMobile
      ? (
        <div>
          <StyledInput ref={searchInputRef} autoFocus className="i18n-tr" name="search" placeholder="Bir arama terimi girin" value={searchText} onChange={onSearchValueChanged} />
          <StyledInput ref={searchInputRef} autoFocus name="search" className="i18n-en" placeholder="Enter a search term" value={searchText} onChange={onSearchValueChanged} />
        </div>
)
      : (
        <StyledInput ref={searchInputRef} autoFocus name="search" placeholder="Bir arama terimi girin/Enter a search term" value={searchText} onChange={onSearchValueChanged} />
      )}
    </FormSearchContainer>
  );
  const headerItemsMemo = useMemo(() => headerItems({ onClickSearchIcon: ['xs', 'sm'].includes(breakpoint) ? null : onSubmitSearch }), [breakpoint, headerItems, onSubmitSearch]);

  return (
    (toogleSearch
      ? (
        <div className="modal">
          <div className="modal-container">
            <div className="buttons">
              <Nav type={breakpoint} items={headerItemsMemo} />
            </div>
            <div className="content">
              <div className="modal-name page-name">
                <div className="i18n-tr">Açık kütüphanede ara</div>
                <div className="i18n-en">Search the open library</div>
              </div>
              <FullWidthDiv className="search-input-div">
                {/* <TransitionDiv visible={isDisplaySearchBar}>
              {SearchComponent}
              <LinkWithIcon marginTopIcon={-2} className="logo-link hide-on-mobile" to="/" local={false}>
                <span style={{ fontSize: 15, whiteSpace: 'nowrap' }}>Artphilein Library Website</span>
              </LinkWithIcon>
            </TransitionDiv> */}
                <TransitionDiv className="search-container" visible>
                  {SearchComponent('')}
                </TransitionDiv>
              </FullWidthDiv>
            </div>
          </div>
        </div>
)
    : null)
  );
};

export default ModalSearch;
