import React, { useEffect } from 'react';
// import { Error, Loading, AbsoluteCenter } from 'components';
// import ArtphileinLibrary from 'services/artphileinlibrary';
// import useApiCall from 'hooks/useApiCall';
// import get from 'lodash/get';
import HomeInfo from './Homeinfo';
// import { useIsInitialLoading } from './hooks/useIsInitialLoading';

// export const useBooksHome = () => {
//   const LIMIT = 15;
//   const { error, data, component, refetch, loading } = useApiCall(() => ArtphileinLibrary.items({ limit: LIMIT }));
//   const items = get(data, 'results');
//   return { refetch, error, items, component, loading };
// };

export default function HomeContainer() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  // const { error, items, refetch, loading } = useBooksHome();
  // const isInitialLoading = useIsInitialLoading(loading);

  // if (isInitialLoading) return (<AbsoluteCenter><Loading center /></AbsoluteCenter>);
  // if (error) return <Error error={error} retry={refetch} />;

  return (
    <div>
      <HomeInfo />
      {/* <div className="home-title">Current and upcoming events</div>
      <CurrentEvents />
      <Button>SHOW ALL EVENTS</Button>
      <Home items={items} /> */}
    </div>
  );
}
